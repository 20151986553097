import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import GuaranteedPercentageYouComp
    from "../../../components/ContentRow/Step2/GuaranteedPercentage/GuaranteedPercentageYouComp";
import GuaranteedPercentageSpouseComp
    from "../../../components/ContentRow/Step2/GuaranteedPercentage/GuaranteedPercentageSpouseComp";
import { secondStepCompleted } from "../../../store/actions/investorProfileActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const GuaranteedPercentage = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'What percentage of your income would you like guaranteed?',
    }

    const title = "Percentage of guaranteed income in retirement"

    useEffect(() => {
        props.secondStepCompleted(false)
    }, [props])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(5/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='guaranteedFee'
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.guaranteedFeeYou === 'Yes' &&
                    <InnerLayoutStepContent type={'you'}>
                        <GuaranteedPercentageYouComp/>
                    </InnerLayoutStepContent>
                }


                {
                    props.isSpouse &&
                    props.guaranteedFeeSpouse === 'Yes'
                    &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <GuaranteedPercentageSpouseComp/>
                    </InnerLayoutStepContent>
                }


            </InnerLayoutStep>

            <Navigation
                isCompleted={true}
                next={retirementCalcLinks.profile}
            />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        guaranteedFeeSpouse: state.investorProfile.guaranteedFeeSpouse,
        guaranteedFeeYou: state.investorProfile.guaranteedFeeYou,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuaranteedPercentage);