import React, { useEffect, useState } from 'react';
import Slider from "react-rangeslider";
import 'react-rangeslider/lib/index.css'
import { connect } from "react-redux";
import { setGuaranteedPercentageYou } from "../../../../store/actions/investorProfileActions";

const GuaranteedPercentageYouComp = ( props ) => {
    const [ value, setValue ] = useState( props.guaranteedPercentageYou || 50);
    const horizontalLabels = {
        1: `1%`,
        50: `50%`,
        100: `100%`
    }

    const onChangeRangeHandler = val => {
        setValue(val)
        props.setGuaranteedPercentageYou(val)
    }

    useEffect(() => {
        setValue(props.guaranteedPercentageYou || 50)
    }, [props])

    const format = val => val + '%'

    return (
        <div className="sliderWrap">
            <Slider
                min={0}
                max={100}
                step={1}
                value={value}
                tooltip={false}
                handleLabel={`${value}%`}
                format={format}
                labels={horizontalLabels}
                onChange={val => onChangeRangeHandler(val)}
            />
        </div>
    )
};

function mapStateToProps(state) {
    return {
        guaranteedPercentageYou: state.investorProfile.guaranteedPercentageYou,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        setGuaranteedPercentageYou: val => dispatch(setGuaranteedPercentageYou(val))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GuaranteedPercentageYouComp);