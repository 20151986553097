import
{
    SET_SHORT_TERM_LOSSES_YOU,
    SET_SHORT_TERM_LOSSES_SPOUSE,
    SET_IS_COMPLETED_SHORT_TERM_LOSSES,
    FETCH_RADIO_ERROR,
    SET_LONG_TERM_RETURNS_YOU,
    SET_IS_COMPLETED_LONG_TERM_RETURNS,
    SET_LONG_TERM_RETURNS_SPOUSE,
    RESET_LONG_TERM_RECOVERY_PERIOD,
    SET_RECOVERY_PERIOD_YOU,
    SET_RECOVERY_PERIOD_SPOUSE,
    SET_GUARANTEED_FEE_YOU,
    SET_GUARANTEED_FEE_SPOUSE,
    SET_GUARANTEED_FEE,
    SET_GUARANTEED_PERCENTAGE_YOU,
    SET_GUARANTEED_PERCENTAGE_SPOUSE,
    SET_RATE_OF_RETURN_YOU,
    SET_RATE_OF_RETURN_SPOUSE,
    SET_PROFILE, SET_SECOND_STEP_COMPLETED, RESET_SECOND_STEP_STATE,
} from "./actionTypes";

import investorProfileInitState from "../initStates/investorProfileInitState";


let localStorageStateIncomeProfile = investorProfileInitState

function setLocalStorageData(params) {
    const investorProfile = localStorage.getItem('investorProfile')

    localStorageStateIncomeProfile = investorProfile ? JSON.parse(investorProfile) : localStorageStateIncomeProfile

    switch (params.type ) {
        case 'setShortTermLossesYou':
            localStorageStateIncomeProfile.shortTermLossesYou = params.val
            if(params.val === 'No') {
                localStorageStateIncomeProfile.longTermReturnsYou = null
                localStorageStateIncomeProfile.recoveryPeriodYou = 3
            }
            break;
        case 'setShortTermLossesSpouse':
            localStorageStateIncomeProfile.shortTermLossesSpouse = params.val
            if(params.val === 'No') {
                localStorageStateIncomeProfile.longTermReturnsSpouse = null
                localStorageStateIncomeProfile.recoveryPeriodSpouse = 3
            }
            break;
        case 'setShortTermLosses':
            localStorageStateIncomeProfile.isCompletedShortTermLosses = false
            if(params.single) {
                if(localStorageStateIncomeProfile.shortTermLossesYou) {
                    localStorageStateIncomeProfile.isCompletedShortTermLosses = true
                }
            }else {
                if(localStorageStateIncomeProfile.shortTermLossesYou && localStorageStateIncomeProfile.shortTermLossesSpouse) {
                    localStorageStateIncomeProfile.isCompletedShortTermLosses = true
                }
            }
            break;
        case 'resetLongTermRecoveryPeriod':
            if(localStorageStateIncomeProfile.shortTermLossesYou){
                localStorageStateIncomeProfile.longTermReturnsYou = null
                localStorageStateIncomeProfile.longTermReturnsSpouse = null
                localStorageStateIncomeProfile.isCompletedLongTermReturns = false
                localStorageStateIncomeProfile.recoveryPeriodYou = 3
                localStorageStateIncomeProfile.recoveryPeriodSpouse = 3
            }
            break;
        case 'setLongTermReturnsYou':
            localStorageStateIncomeProfile.longTermReturnsYou = params.val
            if(params.val === 'No') {
                localStorageStateIncomeProfile.recoveryPeriodYou = 3
            }
            break;
        case 'setLongTermReturnsSpouse':
            localStorageStateIncomeProfile.longTermReturnsSpouse = params.val
            if(params.val === 'No') {
                localStorageStateIncomeProfile.recoveryPeriodSpouse = 3
            }
            break;
        case 'setLongTermReturns':
            localStorageStateIncomeProfile.isCompletedLongTermReturns = false
            if(
                (params.single && localStorageStateIncomeProfile.longTermReturnsYou)
            ||
                (localStorageStateIncomeProfile.longTermReturnsYou && localStorageStateIncomeProfile.longTermReturnsSpouse))
            {
                localStorageStateIncomeProfile.isCompletedLongTermReturns = true
            }
            break;
        case 'onChangeRecoveryPeriodYou':
            localStorageStateIncomeProfile.recoveryPeriodYou = params.val
            break;
        case 'onChangeRecoveryPeriodSpouse':
            localStorageStateIncomeProfile.recoveryPeriodSpouse = params.val
            break;
        case 'setGuaranteedFeeYou':
            localStorageStateIncomeProfile.guaranteedFeeYou = params.val
            if(params.val === 'No') {
                localStorageStateIncomeProfile.guaranteedPercentageYou = 50
            }
            break;
        case 'setGuaranteedFeeSpouse':
            localStorageStateIncomeProfile.guaranteedFeeSpouse = params.val
            if(params.val === 'No') {
                localStorageStateIncomeProfile.guaranteedPercentageSpouse = 50
            }
            break;
        case 'setGuaranteedFee':
            localStorageStateIncomeProfile.isCompletedGuaranteedFee = false
            if(
                (params.single && localStorageStateIncomeProfile.guaranteedFeeYou)
                ||
                (localStorageStateIncomeProfile.guaranteedFeeYou && localStorageStateIncomeProfile.guaranteedFeeSpouse))
            {
                localStorageStateIncomeProfile.isCompletedGuaranteedFee = true
            }
            break;
        case 'setGuaranteedPercentageYou':
            localStorageStateIncomeProfile.guaranteedPercentageYou = params.val
            break;
        case 'setGuaranteedPercentageSpouse':
            localStorageStateIncomeProfile.guaranteedPercentageSpouse = params.val
            break;
        case 'setRateOfReturnYou':
            localStorageStateIncomeProfile.rateOfReturnYou = params.val
            break;
        case 'setRateOfReturnSpouse':
            localStorageStateIncomeProfile.rateOfReturnSpouse = params.val
            break;
        case 'setProfile':
            if (params.person === 'you' ) {
                localStorageStateIncomeProfile.profileYou = params.val
            }
            if (params.person === 'spouse' ) {
                localStorageStateIncomeProfile.profileSpouse = params.val
            }
            break;
        case 'secondStepCompleted':
            localStorageStateIncomeProfile.secondStepCompleted = params.val
            break;
        case 'resetSecondState':
            //localStorageStateIncomeProfile = investorProfileInitState
            break;
        default:
            return true
    }


    localStorage.setItem('investorProfile', JSON.stringify(localStorageStateIncomeProfile));
}

export function setShortTermLossesYou(val) {
    setLocalStorageData({
                            type: 'setShortTermLossesYou',
                            val
                        })

    return {
        type: SET_SHORT_TERM_LOSSES_YOU,
        val
    }
}

export function setShortTermLossesSpouse(val) {
    setLocalStorageData({
                            type: 'setShortTermLossesSpouse',
                            val
                        })

    return {
        type: SET_SHORT_TERM_LOSSES_SPOUSE,
        val
    }
}

export function setShortTermLosses(val = false) {
    setLocalStorageData({
                            type: 'setShortTermLosses',
                            single: val
                        })

    return {
        type: SET_IS_COMPLETED_SHORT_TERM_LOSSES,
        single: val
    }
}

export function resetLongTermRecoveryPeriod() {
    setLocalStorageData({
                            type: 'resetLongTermRecoveryPeriod',
                        })

    return {
        type: RESET_LONG_TERM_RECOVERY_PERIOD,
    }
}

export function setLongTermReturnsYou(val) {
    setLocalStorageData({
                            type: 'setLongTermReturnsYou',
                            val
                        })

    return {
        type: SET_LONG_TERM_RETURNS_YOU,
        val
    }
}

export function setLongTermReturnsSpouse(val) {
    setLocalStorageData({
                            type: 'setLongTermReturnsSpouse',
                            val
                        })

    return {
        type: SET_LONG_TERM_RETURNS_SPOUSE,
        val
    }
}

export function setLongTermReturns(val = false) {
    setLocalStorageData({
                            type: 'setLongTermReturns',
                            single: val
                        })

    return {
        type: SET_IS_COMPLETED_LONG_TERM_RETURNS,
        single: val
    }
}

export function onChangeRecoveryPeriodYou(val) {
    setLocalStorageData({
                            type: 'onChangeRecoveryPeriodYou',
                            val
                        })
    return {
        type: SET_RECOVERY_PERIOD_YOU,
        val
    }
}

export function setChangeRecoveryPeriodSpouse(val) {
    setLocalStorageData({
                            type: 'onChangeRecoveryPeriodSpouse',
                            val
                        })
    return {
        type: SET_RECOVERY_PERIOD_SPOUSE,
        val
    }
}

export function setGuaranteedFeeYou(val) {
    setLocalStorageData({
                            type: 'setGuaranteedFeeYou',
                            val
                        })

    return {
        type: SET_GUARANTEED_FEE_YOU,
        val
    }
}

export function setGuaranteedFeeSpouse(val) {
    setLocalStorageData({
                            type: 'setGuaranteedFeeSpouse',
                            val
                        })

    return {
        type: SET_GUARANTEED_FEE_SPOUSE,
        val
    }
}

export function setGuaranteedFee(val = false) {
    setLocalStorageData({
                            type: 'setGuaranteedFee',
                            single: val
                        })

    return {
        type: SET_GUARANTEED_FEE,
        single: val
    }
}

export function setGuaranteedPercentageYou(val) {
    setLocalStorageData({
                            type: 'setGuaranteedPercentageYou',
                            val
                        })

    return {
        type: SET_GUARANTEED_PERCENTAGE_YOU,
        val
    }
}

export function setGuaranteedPercentageSpouse(val) {
    setLocalStorageData({
                            type: 'setGuaranteedPercentageSpouse',
                            val
                        })

    return {
        type: SET_GUARANTEED_PERCENTAGE_SPOUSE,
        val
    }
}

export function setRateOfReturnYou(val) {
    setLocalStorageData({
                            type: 'setRateOfReturnYou',
                            val
                        })

    return {
        type: SET_RATE_OF_RETURN_YOU,
        val
    }
}

export function setRateOfReturnSpouse(val) {
    setLocalStorageData({
                            type: 'setRateOfReturnSpouse',
                            val
                        })

    return {
        type: SET_RATE_OF_RETURN_SPOUSE,
        val
    }
}

export function setProfile(person, val) {
    setLocalStorageData({
                            type: 'setProfile',
                            val,
                            person
                        })

    return {
        type: SET_PROFILE,
        val,
        person
    }
}

export function secondStepCompleted(val) {
    setLocalStorageData({
                            type: 'secondStepCompleted',
                            val,
                        })

    return {
        type: SET_SECOND_STEP_COMPLETED,
        val,
    }
}

export function resetSecondStep() {

    setLocalStorageData({
                            type: 'resetSecondState',
                        })

    return {
        type: RESET_SECOND_STEP_STATE,
    }

    //localStorage.removeItem('investorProfile')

}

export function fetchRadioError(e) {
    return{
        type: FETCH_RADIO_ERROR,
        error: e
    }
}