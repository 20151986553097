import React, { useEffect, useState } from 'react';
import Slider from "react-rangeslider";
import {
    setGuaranteedPercentageSpouse
} from "../../../../store/actions/investorProfileActions";
import { connect } from "react-redux";

const GuaranteedPercentageSpouseComp = ( props ) => {
    const [ value, setValue ] = useState( props.guaranteedPercentageSpouse || 50);
    const horizontalLabels = {
        1: `1%`,
        50: `50%`,
        100: `100%`
    }

    const onChangeRangeHandler = val => {
        setValue(val)
        props.setGuaranteedPercentageSpouse(val)
    }

    useEffect(() => {
        setValue(props.guaranteedPercentageSpouse || 50)
    }, [props])

    const format = val => val + '%'

    return (
        <div className="sliderWrap">
            <Slider
                min={0}
                max={100}
                step={1}
                value={value}
                handleLabel={`${value}%`}
                tooltip={false}
                format={format}
                labels={horizontalLabels}
                onChange={val => onChangeRangeHandler(val)}
            />
        </div>
    )
};

function mapStateToProps(state) {
    return {
        guaranteedPercentageSpouse: state.investorProfile.guaranteedPercentageSpouse,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        setGuaranteedPercentageSpouse: val => dispatch(setGuaranteedPercentageSpouse(val))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GuaranteedPercentageSpouseComp);